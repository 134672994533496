import { Carousel, Divider, Row, Col, Card } from 'antd';

import './Home.css'


const Home = () => {
    return (
        <>
            <Carousel className='homeCarousel' autoplay effect="fade">
                <img className='carouselPic' height='600px' src="home1.webp" alt="Not Found"/>
                <img className='carouselPic' height='600px' src="home2.webp" alt="Not Found"/>
            </Carousel>

            <Divider />

            <Row className='homeIntro' align="middle">
                <Col xs={{ span:12, offset:0 }} sm={{ span:12,  offset:0 }} md={{ span:12,  offset:1 }} lg={{ span:11, offset:2 }} xl={{ span:10, offset:3 }} xxl={{ span:9, offset:4 }}>
                    <Card title="艾宾浩斯高效复习" bordered={false} >
                        <p>Memory Helper 是一款按照艾宾浩斯遗忘曲线设计的辅助记忆工具，可以添加任何想记忆的内容，旨在帮助用户在最科学的时间点复习，以最少的时间来达到最好的效果。</p>
                    </Card>
                </Col>
                <Col xs={{  offset:0 }} sm={{  offset:1 }} md={{  offset:2 }} lg={{  offset:3 }} xl={{  offset:3 }} xxl={{  offset:3 }}>
                    <img className='homeIntroPic' height='450px' src="intro1.jpg" alt="Not Found"/>
                </Col>
            </Row>

            <Divider />

            <Row className='homeIntro' align="middle">
                <Col xs={{ span:12, offset:0 }} sm={{ span:12,  offset:0 }} md={{ span:12,  offset:1 }} lg={{ span:11, offset:2 }} xl={{ span:10, offset:3 }} xxl={{ span:9, offset:4 }}>
                    <Card title="自由调整复习曲线" bordered={false} >
                        <p>Memory Helper 支持自由调整复习曲线，打造独属于你的高效复习策略。还可以使用智能曲线，更科学地调整你的复习计划！（智能曲线需要高级功能支持）</p>
                    </Card>
                </Col>
                <Col xs={{  offset:0 }} sm={{  offset:1 }} md={{  offset:2 }} lg={{  offset:3 }} xl={{  offset:3 }} xxl={{  offset:3 }}>
                    <img className='homeIntroPic' height='450px' src="intro2.jpg" alt="Not Found"/>
                </Col>
            </Row>

            <Divider />

            <Row className='homeIntro' align="middle">
                <Col xs={{ span:12, offset:0 }} sm={{ span:12,  offset:0 }} md={{ span:12,  offset:1 }} lg={{ span:11, offset:2 }} xl={{ span:10, offset:3 }} xxl={{ span:9, offset:4 }}>
                    <Card title="更多功能" bordered={false} >
                        <p>日历视图，复习任务一览无余</p>
                        <p>云端同步，珍贵数据永不丢失</p>
                        <p>多平台支持，兼容主流iOS及Android系统</p>
                        <p>Markdown助力，让内容更加丰富多彩</p>
                    </Card>
                </Col>
                <Col xs={{  offset:0 }} sm={{  offset:1 }} md={{  offset:2 }} lg={{  offset:3 }} xl={{  offset:3 }} xxl={{  offset:3 }}>
                    <img className='homeIntroPic' height='450px' src="intro3.jpg" alt="Not Found"/>
                </Col>
            </Row>




        </>
    );
}

export default Home;