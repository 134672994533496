
import { Link, Route, Redirect } from 'react-router-dom'
import { Layout, Menu, Typography, Space, Affix, Row, Col } from 'antd';
import { TranslationOutlined } from '@ant-design/icons';

import './App.css';
import Home from './Home/Home'
import Download from './Download/Download'
import SubMenu from 'antd/lib/menu/SubMenu';

const { Header, Content, Footer } = Layout;
const { Title } = Typography

function App() {
  return (
    <Layout style={{minHeight:"100vh"}}>

      <Affix offsetTop={0}>
        <div className='appHeader'>    
          <Row align="bottom" gutter={16}>

            <Col xs={{  offset:0 }} sm={{  offset:0 }} md={{  offset:1 }} lg={{  offset:1 }} xl={{  offset:2 }} xxl={{  offset:3 }}>
              <Row align="bottom" gutter={16}>
                <Col>
                  <img className="navLogo" width="60" src="mhlogo.jpg" alt="No image"/>
                </Col>
                <Col>
                  <Title className='navTitle' level={2}>Memory Helper</Title>
                </Col>
              </Row>
            </Col>
            <Col xs={{ span:20, offset:0 }} sm={{ span:16, offset:0 }} md={{ span:10, offset:0 }} lg={{ span:8, offset:0 }} xl={{ span:8, offset:0 }} xxl={{ span:8, offset:0 }}>
              <Menu mode="horizontal" defaultSelectedKeys={['Home']}>
                <Menu.Item key='Home' className='navButton'>
                  <Link to='Home'>主页</Link>
                </Menu.Item>
                <Menu.Item key='Download' className='navButton'>
                  <Link to='Download'>下载</Link>
                </Menu.Item>
                {/* <Menu.Item key='Help' className='navButton'>
                  <a href='http://help.memoryhelper.net' target="_blank">帮助</a>
                </Menu.Item> */}
                <SubMenu key='Help' className='navButton' title='帮助'>
                  <Menu.Item key='desktopHelp' className='navButton' >
                    <a href='https://z5cu2fpbeq.feishu.cn/docs/doccnnU70wNpSfHyvhZG8cmP3Vc' target="_blank">桌面端FAQ</a>
                  </Menu.Item>
                  <Menu.Item key='privacy' className='navButton' >
                    <a href='http://help.memoryhelper.net' target="_blank">隐私政策</a>
                  </Menu.Item>

                </SubMenu>
                <SubMenu key='Language' className='navButton' icon={<TranslationOutlined />} title='中文'>
                  <Menu.Item key='zh' className='navButton' >中文
                    {/* <a href='https://memoryhelperapp.fujianjin6471.com:3000' target="_blank">中文</a> */}
                  </Menu.Item>
                  {/* <Menu.Item key='en' className='navButton' >
                    <a href='https://memoryhelperapp.fujianjin6471.com:3000' target="_blank">English</a>
                  </Menu.Item> */}

                </SubMenu>


              </Menu>
            </Col>
          </Row>
        </div>
      </Affix>

      <Content className='appContent'>
        <Route path='/Home' component={Home} />
        <Route path='/Download' component={Download} />
        <Redirect to='/Home' />
      </Content>

      <Footer style={{ textAlign: 'center' }}>
        <Space>
          温州云忆剑影科技有限公司©️2023
          <a href='https://beian.miit.gov.cn/' target="_blank" class='footText'>浙ICP备2021036035号</a>
        </Space>
      </Footer>

    </Layout>
  );
}

export default App;