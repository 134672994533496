import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'

// import { addLocaleData, IntlProvider } from 'react-intl'; /* react-intl imports */
// import zh_CN from "./locale/zh_CN"     // import defined messages in Chinese
// import en_US from "./locale/en_US"     // import defined messages in English

import './index.css';
import App from './App';
import 'antd/dist/antd.css'






ReactDOM.render(

  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
