
import { Card, Row, Col, Button, Space, Popover } from 'antd';
import { AppleOutlined, AndroidOutlined, DownloadOutlined, WindowsOutlined } from '@ant-design/icons';

import './Download.css'

const androidTitle = <Space><DownloadOutlined />移动端下载</Space>
const appleTitle = <Space><DownloadOutlined />桌面端下载</Space>

const apkDL = <img className='apkDLPic' height='100px' src="apkDL.png" alt="Not Found"/>
const kuanDL = <img className='kuanDLPic' height='100px' src="kuanDL.png" alt="Not Found"/>
const appStoreDL = <img className='appStoreDLPic' height='100px' src="appStoreDL.png" alt="Not Found"/>

const apkButt = () => {
    window.location.href = "https://memoryhelper.net/app-shengchan-release.apk";
}

const exeButt = () => {
    window.location.href = "https://memoryhelper.net/memoryhelper-win.exe";
}

const dmgButt = () => {
    window.location.href = "https://memoryhelper.net/memoryhelper-mac.dmg";
}

const kuanButt = () => {
    window.location.href = "https://www.coolapk.com/apk/com.fujianjin6471.memoryhelper";
}

const appStoreButt = () => {
    window.location.href = "https://apps.apple.com/cn/app/memory-helper-艾宾浩斯遗忘曲线记忆助手/id1113262919";
}

const Download = () => {
    return (
        <>
            <Row className='downloadLinks' align="top">
            
                <Col xs={{ span:20, offset:4 }} sm={{ span:16, offset:6 }} md={{ span:8, offset:3 }} lg={{ span:8, offset:4 }} xl={{ span:8, offset:5 }} xxl={{ span:8, offset:6 }}>
                
                    <Card title={appleTitle} bordered={false} style={{ width: 300 }}>
                        <Button type="primary" shape="round" icon={<WindowsOutlined />} onClick={exeButt}>Windows端下载</Button>       
                        <br/><br/>
                        <Button type="primary" shape="round" icon={<AppleOutlined />} onClick={dmgButt}>MacOS端下载</Button>       
                    </Card>

                </Col>

                <Col xs={{ span:20, offset:4 }} sm={{ span:16, offset:6 }} md={{ span:8, offset:1 }} lg={{ span:8, offset:1 }} xl={{ span:8, offset:1 }} xxl={{ span:8, offset:1 }}>
  
                    <Card title={androidTitle} bordered={false} style={{ width: 300 }} >

                        <Popover content={appStoreDL} placement='left'>
                            <Button type="primary" shape="round" icon={<AppleOutlined />} onClick={appStoreButt}>App Store下载</Button>       
                        </Popover>

                        <br /><br />

                        <Popover content={apkDL} placement='left'>
                            <Button type="primary" shape="round" icon={<AndroidOutlined />} onClick={apkButt}>APK下载</Button>
                        </Popover>

                        <br /><br />

                        <Popover content={kuanDL} placement='left'>
                            <Button type="primary" shape="round" icon={<AndroidOutlined />} onClick={kuanButt}>酷安应用商店下载</Button>
                        </Popover>

                        <br /><br />

                        <div className='downloadDesc'>* 华为，小米，OPPO，VIVO用户均可在各自系统应用商店中搜索“Memory Helper”下载安装</div>
                    </Card>





                </Col>

            </Row>
        </>
    );
}

export default Download;